<template>
  <div class="container dispatchTasks-warp">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/pfrw.png')" alt="" />
      <div class="head-bottom-warp">
        <div class="head-item align-left blue">
          <div class="bg-blur"></div>
          <img
            :src="require('@/assets/image/xqyj.png')"
            class="img-icon"
          /><span>任务转交</span>
        </div>
      </div>
    </div>
    <div class="panel-warp">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block">
            转移人员<span class="red">*</span>
          </div>
          <div
            class="panel-item-right bra3 panel-item-block plr edit-sel"
            @click="handleZyPeople"
          >
            <span v-if="ruleforms.assign">{{ ruleforms.assign }}</span>
            <span class="plh" v-else>请选择</span>
            <van-icon name="arrow-down" class="icon" />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            电话号码<span class="red">*</span>
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <input
              v-model="ruleforms.assign_phone"
              class="panel-input"
              placeholder="请输入电话号码"
              @change="countNumber"
            />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            转交说明
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <textarea
              v-model="ruleforms.hdl_info"
              placeholder="请输入你要转交的原由"
              rows="2"
            ></textarea>
          </div>
        </li>
      </ul>
      <div class="warp-btn">
        <van-button type="info" color="rgb(38, 120, 255)" @click="handleSubmit"
          >立即转交</van-button
        >
      </div>
      <!-- 人员 -->
      <!-- <van-popup v-model="personShow" position="bottom">
        <van-picker
          title="选择人员"
          show-toolbar
          :columns="personList"
          @confirm="onConfirmperson"
          @cancel="onCancelperson"
        />
      </van-popup> -->
        <!-- 选人选房屋弹窗 -->
    <selectPop requestUrl="/api/staff/get" :queryParams="{area_id: [], name: ''}" ref="selectPop" @submit="handleSubmitSel"></selectPop>
    </div>
  </div>
</template>

<script>
import httpService from "@/plugins/http";
import { mapState } from "vuex";
import { Toast } from "vant";
export default {
  data() {
    return {
      searchValue: "",
      ruleforms: {
        assign:"",
        assign_phone:""
      },
      images: [require("@/assets/image/home1.png")],
      personShow: false, // 人员
      personList: [],
      persongDataList: [],
    };
  },
  computed: {
    ...mapState(["staff_type"]),
  },
  mounted() {
    this.getStaffInfo();
  },
  methods: {
    async getStaffInfo() {
      try {
        this.$store.commit("showLoading");
        const res = await httpService.post("/api/staff/get", {
          area_id: [],
          name: "",
        });
        if (res.code === 0) {
          console.log(res, "lallal");
          this.persongDataList = JSON.parse(JSON.stringify(res.data));
          this.personList = this.persongDataList.map((item) => {
            return item.name;
          });

          console.log(this.personList, "this.personList");
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("hideLoading");
      }
    },
    countNumber() {
      let reg_tel =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!reg_tel.test(this.ruleforms.assign_phone)) {
        Toast("请填写正确的手机号码");
        this.ruleforms.assign_phone = "";
        return;
      }
    },
    handleZyPeople() {
      this.$refs.selectPop.init();
    },
    handleSubmitSel(item) {
      this.$set(this.ruleforms,'assign',item.name);
      this.$set(this.ruleforms,'assign_phone',item.phone);
    },
    onCancelperson() {
      this.personShow = false;
    },
    // 提交
    async handleSubmit() {
      if (!this.ruleforms.assign) {
        Toast("请选择转移人员");
        return;
      }
      if (!this.ruleforms.assign_phone) {
        Toast("请输入电话号码");
        return;
      }
      try {
        this.$store.commit("showLoading");
        const { code } = await httpService.post("/api/event/handle", {
          ...this.ruleforms,
          state: 3,
          id: Number(this.$route.query.id),
        });
        if (code === 0) {
          Toast("提交成功");
          this.$router.push({
            path: "dispatchTasks",
            query: {
              id: this.$route.query.id,
            },
          });
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("hideLoading");
      }
    },
  },
};
</script>

<style lang="less">
.dispatchTasks-warp {
  letter-spacing: 0px;
  position: relative;
  overflow: auto;
}
.warp-btn {
  display: flex;
  padding: 20px;
  justify-content: center;
  .van-button {
    width: 112px;
  }
}
</style>
